import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"

export const pageQuery = graphql`
  query HomeQuery($id: String!){
		markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title_seo
        description_seo
        box1 {
          imageBackground {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
              sizes {
                src
              }
            }
          }
        }
        box2 {
          imageBackground {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
              sizes {
                src
              }
            }
          }
          link
          title
          description
        }
        box3 {
          imageBackground {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
              sizes {
                src
              }
            }
          }
          link
          title
          description
        }
        box4 {
          imageBackground {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
              sizes {
                src
              }
            }
          }
          link
          title
          description
        }
        box5 {
          imageBackground {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
              sizes {
                src
              }
            }
          }
          link
          description
        }
        box6 {
          imageBackground {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
              sizes {
                src
              }
            }
          }
          link
          title
          description
        }
        box7 {
          imageBackground {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
              sizes {
                src
              }
            }
          }
          link
          title
          description
        }
        box8 {
          imageBackground {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
              sizes {
                src
              }
            }
          }
          link
          title
          description
        }
        box9 {
          imageBackground {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
              sizes {
                src
              }
            }
          }
          link
          title
          description
        }
        box10 {
          imageBackground {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
              sizes {
                src
              }
            }
          }
          link
          title
          description
        }
      }
    }
  }
`

const HomePage = ({data}) => {
  const { markdownRemark } = data
  const { frontmatter } = markdownRemark

	return (
		<Layout>
      <SEO 
        title={frontmatter.title_seo}
        description={frontmatter.description_seo} 
      />
      <section className="home-section top-espaco">
        <div className="container">
          <div className="row">
            <div className={`box-maior box-logo box-left logo-bg`}>
              <div className="logo-space space1"></div>
              <div className="logo-space space2"></div>
              <div className="logo-space space3"></div>
              <div className="logo-space space4"></div>
              <div className="logo-space space5"></div>
              <div className="logo-space space6"></div>
              <Img fluid={frontmatter.box1.imageBackground.childImageSharp.fluid} />
            </div>
            <a 
              href={frontmatter.box2.link}
              className={`box-menor box-logo box-right box-hover f1-bg`}
            >
              <div className="hover-box">
                <div className="text-box">
                  <h3>{frontmatter.box2.title}</h3>
                  <hr />
                  <div>{frontmatter.box2.description}</div>
                </div>
              </div>
              <Img fluid={frontmatter.box2.imageBackground.childImageSharp.fluid} />
            </a>
          </div>

          <div className="row fileira-2">
            <a 
              href={frontmatter.box3.link}
              className={`box-maior box-left box-hover maior-bg`}
            >
              <div className="hover-box">
                <div className="text-box">
                  <h3>{frontmatter.box3.title}</h3>
                  <hr />
                  <div>{frontmatter.box3.description}</div>
                </div>
              </div>
              <Img fluid={frontmatter.box3.imageBackground.childImageSharp.fluid} />
            </a>
            <a 
              href={frontmatter.box4.link}
              className={`box-menor box-right box-hover menor-bg`}
            >
              <div className="hover-box">
                <div className="text-box">
                  <h3>{frontmatter.box4.title}</h3>
                  <hr />
                  <div>{frontmatter.box4.description}</div>
                </div>
              </div>
              <Img fluid={frontmatter.box4.imageBackground.childImageSharp.fluid} />
            </a>
          </div>

          <div className="row home-info">
            <div className="col-md-6">
              <div className="centraliza">
                <img className="info-img" src="assets/logo.svg" alt="logo s12 arquitetura" />
                <p>
                  <span className="info-faixa"></span>
                  {frontmatter.box5.description}
                </p>
                <a href={frontmatter.box5.link}>
                  <p className="clearfix">
                    <span className="saibam square individual">
                      <span className="ninth before after">SAIBA MAIS</span>
                    </span>
                  </p>
                </a>
              </div>                    
            </div>
            <Img 
              className={`col-md-6 col-lg-6 img-hinfo hidden-xs hidden-sm`}
              fluid={frontmatter.box5.imageBackground.childImageSharp.fluid} 
            />
          </div>

          <div className="row fileira-4">
            <a 
              href={frontmatter.box6.link}
              className={`box-menor box-left box-hover menor-bg`}
            >
              <div className="hover-box">
                <div className="text-box">
                  <h3>{frontmatter.box6.title}</h3>
                  <hr />
                  <div>{frontmatter.box6.description}</div>
                </div>
              </div>
              <Img fluid={frontmatter.box6.imageBackground.childImageSharp.fluid} />
            </a>
            <a 
              href={frontmatter.box7.link}
              className={`box-maior box-right box-hover maior-bg`}
            >
              <div className="hover-box">
                <div className="text-box">
                  <h3>{frontmatter.box7.title}</h3>
                  <hr />
                  <div>{frontmatter.box7.description}</div>
                </div>
              </div>
              <Img fluid={frontmatter.box7.imageBackground.childImageSharp.fluid} />
            </a>
          </div>

          <div className="row fileira-5">
            <a 
              href={frontmatter.box8.link}
              className={`box-meio box-hover maior-bg`}
            >
              <div className="hover-box">
                <div className="text-box">
                  <h3>{frontmatter.box8.title}</h3>
                  <hr />
                  <div>{frontmatter.box8.description}</div>
                </div>
              </div>
              <Img fluid={frontmatter.box8.imageBackground.childImageSharp.fluid} />
            </a>
          </div>

          <div className="row fileira-6">
            <a 
              href={frontmatter.box9.link}
              className={`box-menor box-left box-hover menor-bg`}
            >
              <div className="hover-box">
                <div className="text-box">
                  <h3>{frontmatter.box9.title}</h3>
                  <hr />
                  <div>{frontmatter.box9.description}</div>
                </div>
              </div>
              <Img fluid={frontmatter.box9.imageBackground.childImageSharp.fluid} />
            </a>
            <a 
              href={frontmatter.box10.link}
              className={`box-maior box-right box-hover maior-bg`}
            >
              <div className="hover-box">
                <div className="text-box">
                  <h3>{frontmatter.box10.title}</h3>
                  <hr />
                  <div>{frontmatter.box10.description}</div>
                </div>
              </div>
              <Img fluid={frontmatter.box10.imageBackground.childImageSharp.fluid} />
            </a>
          </div>

        </div>
      </section>
		</Layout>
	)
}

export default HomePage
